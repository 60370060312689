<template>
  <div class="right-menu shipping-right">
     <div class="db-blue-section">
      <div class="db-container">
        <div class="row">
          <div class="col-md-12 blue-section-content">
            <div class="mobie-nav-h opensideBarDashboard">
              <!-- <a href="#." @click="showSideBarDb" class="openSideBarBtn" -->
              <a href="#." @click="showSideBarDb" class="openSideBarBtn">
                <svg
                  fill="#fff"
                  viewBox="0 0 32 32"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <title />
                  <g data-name="menu " id="menu_">
                    <path d="M29,6H3A1,1,0,0,0,3,8H29a1,1,0,0,0,0-2Z" />
                    <path d="M3,17H16a1,1,0,0,0,0-2H3a1,1,0,0,0,0,2Z" />
                    <path d="M25,24H3a1,1,0,0,0,0,2H25a1,1,0,0,0,0-2Z" />
                  </g>
                </svg>
              </a>
              <h2>Reset Password</h2>
            </div>

            <!-- mobile-view Dashboard end-->

            <div class="blue-right-content">
              <div class="mobile-resp-icons">
                <ul>
                  <li>
                    <a href="#.">
                      <img src="../../assets/images/shipping-4@2x.png"/>
                    </a>
                  </li>
                  <li>
                    <a href="#.">
                      <img src="../../assets/images/shipping-3.png"/>
                    </a>
                  </li>
                  <li>
                    <a href="#.">
                      <img src="../../assets/images/shipping-2@2x.png"/>
                    </a>
                  </li>
                  <li>
                    <a href="#.">
                      <img src="../../assets/images/shipping-icon-1@2x.png"/>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- red-tab -->
     <div class="db_top">
      <div class="db-container">
        <div class="order-banner">
          <div class="row pro-box-img">
            <div class="col-md-4">
              <div class="top-boxes cyan-box">
                <div class="amout-list">
                  <h2>34K</h2>
                  <img class="img-1" src="../../assets/images/ice-c@2x.png" />
                </div>
                <p>TOTAL PRODUCTS</p>
              </div>
            </div>

            <div class="col-md-4">
              <div class="top-boxes yellow-box">
                <div class="amout-list">
                  <h2>98K</h2>
                  <img class="img-2" src="../../assets/images/lamp@2x.png" />
                </div>
                <p>ACTIVE PRODUCTS</p>
              </div>
            </div>

            <div class="col-md-4">
              <div class="top-boxes red-box">
                <div class="amout-list">
                  <h2>16K</h2>
                  <img class="img-3" src="../../assets/images/shirt-p@2x.png" />
                </div>
                <p>OUT OF STOCK</p>
              </div>
            </div>
          </div>
        </div>
      </div>
     </div>
  </div>
</template>

<script>
export default {
  name: "reset-password",
  methods:{
    showSideBarDb() {
      var element = document.getElementsByTagName("body")[0];
      element.classList.remove("hideSideBar");
      element.classList.add("activeIpad");
    },
  },
  beforeMount(){
    console.log(this.$route.query);
  }
}
</script>